import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { getBackHost } from './settings';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isProtected: true
};

const slice = createSlice({
  name: 'authPublicJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // LOGIN
    verifySuccess(state) {
      //
    },

    // LOGOUT
    getProtectedSuccess(state, action) {
      state.isLoading = false;
      state.isProtected = action.payload;
    },

    // LOGOUT
    logoutSuccess(state) {
      //
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const setSession = (token) => {
  if (token) {
    localStorage.setItem('publicViewToken', token);
  } else {
    localStorage.removeItem('publicViewToken');
  }
};

// ----------------------------------------------------------------------

export function login({ cid, password }) {
  return async (dispatch) => {
    const response = await axios.post(getBackHost() + '/g/l',
      {
        'cid': cid,
        'password': password
      });

    const { token } = response.data;

    if (token) {
      setSession(token);
      dispatch(slice.actions.verifySuccess());
    } else {
      return response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function checkProtected(cid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const response = await axios.post(getBackHost() + '/g/c',
      {
        'cid': cid
      });

    const { isProtected } = response.data;

    dispatch(slice.actions.getProtectedSuccess(isProtected));
    return isProtected;
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}
