import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from 'src/redux/slices/authPublicJwt';

// ----------------------------------------------------------------------

usePublicAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function usePublicAuth() {

  // JWT Auth
  const dispatch = useDispatch();
  const { isLoading, isProtected } = useSelector(
    (state) => state.authPublicJwt
  );

  return {
    method: 'jwt',
    isLoading: isLoading,
    isProtected: isProtected,

    login: ({ cid, password }) =>
      dispatch(
        login({
          cid: cid,
          password: password
        })
      ),
    logout: () => dispatch(logout())
  };

}
