import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Switch,
  TextField,
  DialogContent,
  DialogActions,
  FormControlLabel, InputAdornment, IconButton, Box
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify-icons/eva/eye-fill';
import eyeOffFill from '@iconify-icons/eva/eye-off-fill';
import { passwordError } from '../../../utils/helpError';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { createContainer } from '../../../redux/slices/user';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {},
  margin: {
    marginBottom: theme.spacing(3)
  }
}));

// ----------------------------------------------------------------------

CreateContainerForm.propTypes = {
  onCancel: PropTypes.func
};

function CreateContainerForm({ onCancel }) {

  const classes = useStyles();

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const [showPassBox, setShowPassBox] = useState(true);

  const isMountedRef = useIsMountedRef();

  const handleSwitchCheck = () => {
    setShowPassBox((showBox) => !showBox);
    formik.setFieldValue('isProtected', !showPassBox);
  };

  const handleShowPassword = () => {
    setShowPassword((showPass) => !showPass);
  };

  const CreateContainerSchema = Yup.object().shape({
    id: Yup.string().max(250).required('Container ID is required.'),
    description: Yup.string().max(250).required('required'),
    isProtected: Yup.bool(),
    password: Yup.string().when('isProtected', {
      is: true,
      then: Yup.string().min(6).max(64).required('Password is required')
    })
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      description: '',
      isProtected: true,
      password: ''
    },
    validationSchema: CreateContainerSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {

      const data = {
        name: values.id,
        description: values.description,
        isProtected: values.isProtected,
        password: values.password
      };

      try {

        await dispatch(createContainer(data));

        onCancel();
        setSubmitting(false);
      } catch (error) {
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.code || error.message });
        }
      }
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogContent>

          <Box sx={{ mb: 3 }}/>
          <TextField
            fullWidth
            label="Container ID"
            {...getFieldProps('id')}
            error={Boolean(touched.id && errors.id)}
            helperText={touched.id && errors.id}
            className={classes.margin}
          />

          <TextField
            fullWidth
            multiline
            maxRows={4}
            label="Description"
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            className={classes.margin}
          />

          <Box
            fullwidth
          >
            <FormControlLabel
              control={
                <Switch
                  {...getFieldProps('isProtected')}
                  error={Boolean(touched.isProtected && errors.isProtected)}
                  helperText={touched.isProtected && errors.isProtected}
                  checked={showPassBox}
                  fullWidth
                  onChange={handleSwitchCheck}
                />
              }
              label="Password Protect"
              className={classes.margin}
            />
          </Box>

          {showPassBox && (
            <TextField
              fullWidth
              className={classes.margin}
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={
                Boolean(touched.password && errors.password) ||
                passwordError(errors.afterSubmit).error
              }
              helperText={
                (touched.password && errors.password) ||
                passwordError(errors.afterSubmit).helperText
              }
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            fullWidth
            type="button"
            variant="outlined"
            color="inherit"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            pending={isSubmitting}
            pendingIndicator="Loading..."
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}

export default CreateContainerForm;
