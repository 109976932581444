// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app',
  docs: '/docs'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify/:id/:code')
  },
  container: {
    verify: {
      root: '/verify',
      single: '/verify/:id'
    },
    view: {
      base: '/view',
      root: '/view/containers',
      view_root: '/view/containers/:id',
      view_depth: '/view/containers/:id/:path+'
    }
  }
};

export const PATH_HOME = {
  components: '/components',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce'),
    analytics: path(ROOTS.app, '/dashboard/analytics')
  },
  management: {
    root: path(ROOTS.app, '/management'),
    user: {
      root: path(ROOTS.app, '/management/user'),
      new: path(ROOTS.app, '/management/user/new'),
      list: path(ROOTS.app, '/management/user/list'),
      edit: path(ROOTS.app, '/management/user/edit')
    },
    container: {
      root: path(ROOTS.app, '/management/containers'),
      view: path(ROOTS.app, '/management/containers/:id'),
      view_depth: path(ROOTS.app, '/management/containers/:id/:path+')
    }
  }
};
