import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  themeMode: 'dark',
  themeDirection: 'ltr'
};

const backendConfig = {
  schema: 'https',
  host: 'qr.hktm.com.tr',
  port: '8080'
};

const frontEndConfig = {
  schema: 'https',
  host: 'qr.hktm.com.tr',
  port: '443'
};

export function getBackHost() {
  return backendConfig.schema + '://' + backendConfig.host + ':' + backendConfig.port;
}

export function getFrontHost() {
  return frontEndConfig.schema + '://' + frontEndConfig.host + ':' + frontEndConfig.port;
}

export function getFrontHostWithoutPort() {
  return frontEndConfig.schema + '://' + frontEndConfig.host;
}

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    switchMode(state, action) {
      state.themeMode = action.payload;
    },
    switchDirection(state, action) {
      state.themeDirection = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { switchMode, switchDirection } = slice.actions;
