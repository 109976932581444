import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { getBackHost } from './settings';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  container: {},
  files: []
};

const slice = createSlice({
  name: 'publicContainer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Container
    getContainerSuccess(state, action) {
      state.isLoading = false;
      state.container = action.payload;
    },

    getFilesSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    downloadSuccess(state) {
      state.isLoading = false;
    },

    // Set error false
    setErrorFalse(state) {
      state.error = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPublicContainer(id, data, setReq) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const publicViewToken = localStorage.getItem('publicViewToken');

      const postData = {
        token: publicViewToken,
        container: {
          cid: id,
          'path': data ? data : ''
        }
      };

      const response = await axios.post(getBackHost() + '/g/v/' + id, postData);
      setReq(true);

      if (await response.data.message) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getContainerSuccess(response.data));
        dispatch(slice.actions.getFilesSuccess(response.data.files));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function downloadObject(object, cid, name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const publicViewToken = localStorage.getItem('publicViewToken');

      const postData = {
        validate: {
          token: publicViewToken
        },
        cid: cid,
        object: object
      };

      const response = await axios.post(getBackHost() + '/g/d', postData, { responseType: 'blob' });

      if (response.data.message || response.data.size < 100) {
        dispatch(slice.actions.hasError({ code: 200, message: 'can\'t download the object', variant: 'error' }));
      } else {
        saveAs(response.data, name);
        dispatch(slice.actions.downloadSuccess(response.data));
      }


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setError() {
  return async (dispatch) => {
    dispatch(slice.actions.setErrorFalse());
  };
}

// ----------------------------------------------------------------------
