import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP } from 'src/routes/paths';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  file: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
};

const navConfig = [
  {
    subheader: 'management',
    items: [
      {
        title: 'user',
        icon: ICONS.user,
        href: PATH_APP.management.user.root,
      },
      {
        title: 'container',
        icon: ICONS.file,
        href: PATH_APP.management.container.root,
      },
    ]
  }
];

export default navConfig;
