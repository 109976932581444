import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, updateProfile } from 'src/redux/slices/authJwt';

// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth() {

  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );

  return {
    method: 'jwt',
    user: user,
    isLoading: isLoading,
    isAuthenticated: isAuthenticated,

    login: ({ email, password }) =>
      dispatch(
        login({
          email: email,
          password: password
        })
      ),

    logout: () => dispatch(logout()),

    resetPassword: () => {
    },

    updateProfile: (data) =>
      dispatch(
        updateProfile(data)
      )
  };


}
