import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';
import usePublicAuth from '../../hooks/usePublicAuth';
import jwtDecode from 'jwt-decode';
import { checkProtected } from '../../redux/slices/authPublicJwt';
import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

ContainerProtect.propTypes = {
  children: PropTypes.node
};

const isValidToken = (publicViewToken, cid) => {
  if (!publicViewToken) {
    return false;
  }
  const decoded = jwtDecode(publicViewToken);
  const currentTime = Date.now();

  return ((decoded.exp * 1000 > currentTime) && (decoded.cid === cid));
};

function ContainerProtect({ children }) {
  const dispatch = useDispatch();
  let { id } = useParams();

  useEffect(() => {
    dispatch(checkProtected(id));
  }, [dispatch, id]);

  const { isLoading, logout, isProtected } = usePublicAuth();

  const token = localStorage.getItem('publicViewToken');

  if (isLoading) {
    return <LoadingScreen/>;
  }

  if (isProtected) {
    if (isValidToken(token, id)) {
      return <>{children}</>;
    } else {
      logout();
      return <Redirect to={PATH_PAGE.container.verify.root + '/' + id}/>;
    }
  } else {
    return <>{children}</>;
  }

}

export default ContainerProtect;
