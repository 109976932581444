import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.root,
      component: lazy(() => import('src/views/user/UserListView'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />
    },

    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.user.new,
      component: lazy(() => import('src/views/user/UserNew'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.list,
      component: lazy(() => import('src/views/user/UserListView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.edit,
      component: lazy(() => import('src/views/user/AccountView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.root,
      component: () => <Redirect to={PATH_APP.management.user.list} />
    },


    // MANAGEMENT : File
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.container.root,
      component: lazy(() => import('src/views/container/ContainerList'))
    },
    {
      exact: true,
      path: PATH_APP.management.container.view,
      component: lazy(() => import('src/views/container/ContainerView'))
    },
    {
      exact: true,
      path: PATH_APP.management.container.view_depth,
      component: lazy(() => import('src/views/container/ContainerView'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
