import React from 'react';
import { Button, Box, DialogTitle } from '@material-ui/core';
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import {
  openCreateContainerModal,
  closeCreateContainerModal
} from 'src/redux/slices/user';
import { useDispatch, useSelector } from 'react-redux';
import { DialogAnimate } from '../../../components/Animate';
import CreateContainerForm from './CreateContainerForm';


// ----------------------------------------------------------------------

function Compose() {

  const { isOpenModal } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(openCreateContainerModal());
  };

  const handleCloseModal = () => {
    dispatch(closeCreateContainerModal());
  };

  return (

    <Box sx={{ px: 2.5, py: 1 }}>
      <Button
        size="small"
        fullWidth
        variant="contained"
        startIcon={<Icon icon={plusFill}/>}
        onClick={handleOpenModal}
      >
        Create a Project
      </Button>


      <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
        <DialogTitle>New Project</DialogTitle>
        <CreateContainerForm
          onCancel={handleCloseModal}
        />
      </DialogAnimate>
    </Box>
  );
}

export default Compose;
