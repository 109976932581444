import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { getBackHost } from './settings';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  container: {},
  containers: [],
  files: []
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Container
    getContainerSuccess(state, action) {
      state.isLoading = false;
      state.container = action.payload;
    },

    // GET Container
    getContainersSuccess(state, action) {
      state.isLoading = false;
      state.containers = action.payload;
    },

    getFilesSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    downloadSuccess(state) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function uploadFile(bodyFormData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axios.post(getBackHost() + '/c/f', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.message) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getContainerSuccess(response.data));
        dispatch(slice.actions.getFilesSuccess(response.data.files));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getContainer(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const postData = { 'path': data ? data : '' };

      const response = await axios.post(getBackHost() + '/c/v/' + id, postData);

      if (response.data.message) {
        dispatch(slice.actions.hasError(response.data));
      } else {
        dispatch(slice.actions.getContainerSuccess(response.data));
        dispatch(slice.actions.getFilesSuccess(response.data.files));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPublicContainer(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const publicViewToken = localStorage.getItem('publicViewToken');
      if (publicViewToken) {

        const postData = {
          token: publicViewToken,
          container: {
            cid: id,
            'path': data ? data : ''
          }
        };

        const response = await axios.post(getBackHost() + '/g/v/' + id, postData);

        if (await response.data.message) {
          dispatch(slice.actions.hasError(response.data));
        } else {
          dispatch(slice.actions.getContainerSuccess(response.data));
          dispatch(slice.actions.getFilesSuccess(response.data.files));
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function downloadObject(object, cid, name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const publicViewToken = localStorage.getItem('publicViewToken');

      if (publicViewToken) {

        const postData = {
          validate: {
            token: publicViewToken
          },
          cid: cid,
          object: object
        };

        const response = await axios.post(getBackHost() + '/g/d', postData, { responseType: 'blob' });

        if (response.data.message) {
          dispatch(slice.actions.hasError(response.data));
        } else {
          saveAs(response.data, name);
          dispatch(slice.actions.downloadSuccess(response.data));
        }
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteObject(file, container, path) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      let postData = {
        ...container
      };
      postData['path'] = path;
      postData['files'] = [file];

      const response = await axios.post(getBackHost() + '/c/r', postData);

      dispatch(slice.actions.getContainerSuccess(response.data));
      dispatch(slice.actions.getFilesSuccess(response.data.files));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(getBackHost() + '/c/v/');
      dispatch(slice.actions.getContainerSuccess(response.data.containers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


