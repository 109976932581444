import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { getBackHost } from './settings';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: null
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },

    // LOGIN
    updateSuccess(state, action) {
      state.user = action.payload;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now();

  return decoded.exp*1000 > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export function login({ email, password }) {
  return async (dispatch) => {
    const response = await axios.post(getBackHost() + '/a/l',
      {
        'email': email,
        'password': password
      });

    if (!response.data.message) {
      setSession(response.data.accessToken);
      dispatch(slice.actions.loginSuccess(response.data));
    } else {
      return response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function updateProfile(data) {
  return async (dispatch) => {

    const { firstName, lastName, email, company, src, file } = data

    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('company', company);
    formData.append('email', email);
    formData.append('src', src);

    if (file) {
      formData.append('file', file);
    }

    const response = await axios.post(getBackHost() +'/u/u', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (!response.data.message) {
      dispatch(slice.actions.updateSuccess(response.data));
    } else {
      return response.data;
    }
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    localStorage.clear();
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(getBackHost() +'/a/v');

        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response.data
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
