import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import productReducer from './slices/product';
import authJwtReducer from './slices/authJwt';
import authPublicJwtReducer from './slices/authPublicJwt';
import settingsReducer from './slices/settings';
import publicContainerReducer from './slices/publicContainer';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const publicContainerPersistConfig = {
  key: 'publicContainer',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy']
};

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const authPublicPersistConfig = {
  key: 'authPublicJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  authPublicJwt: persistReducer(authPublicPersistConfig, authPublicJwtReducer),
  publicContainer: persistReducer(publicContainerPersistConfig, publicContainerReducer)
});

export { rootPersistConfig, rootReducer };
