import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import React from 'react';
import { getBackHost } from './settings';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: {},
  containers: [],
  isOpenModal: false,
  users: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get Container List
    getContainersSuccess(state, action) {
      state.isLoading = false;
      state.containers = action.payload;
    },

    // Get My Profile
    getMyProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // Get My Profile
    updateProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // Create Container Success
    createContainerSuccess(state, action) {
      state.isLoading = false;
      state.containers = action.payload;
    },

    // Create Container Success
    createUserSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // Create Container Success
    deleteUserSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // Delete container
    deleteContainerSuccess(state, action) {
      state.isLoading = false;
      state.containers = action.payload;
    },

    // Open Compose
    openCreateContainerModal(state) {
      state.isOpenModal = true;
    },

    // Close Compose
    closeCreateContainerModal(state) {
      state.isOpenModal = false;
    },

    // Set error false
    setErrorFalse(state) {
      state.error = false;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    }

  }
});

// Reducer
export default slice.reducer;

// Actions

export const { openCreateContainerModal, closeCreateContainerModal } = slice.actions;

// ----------------------------------------------------------------------


export function getContainerList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(getBackHost() + '/c/v');

      const { containers } = response.data;

      if (containers) {
        dispatch(slice.actions.getContainersSuccess(containers));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(getBackHost() + '/u/m');

      if (!response.data.message) {
        dispatch(slice.actions.getMyProfileSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProfile(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(getBackHost() + '/u/u', data);

      if (!response.data.message) {
        dispatch(slice.actions.getMyProfileSuccess(response.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function changePassword(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(getBackHost() + '/u/p', data);

      dispatch(slice.actions.hasError(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(getBackHost() + '/u/v');

      const { users } = response.data;

      if (users) {
        dispatch(slice.actions.getUsersSuccess(users));
      }else{
        dispatch(slice.actions.hasError(response.data));
      }

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createContainer(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(getBackHost() + '/c/c', data);

      const { containers } = response.data;

      dispatch(slice.actions.createContainerSuccess(containers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createUser(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(getBackHost() + '/u/c', data);

      const { users } = response.data;

      dispatch(slice.actions.createUserSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUser(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(getBackHost() + '/u/d', data);

      const { users } = response.data;

      if (users) {
        dispatch(slice.actions.deleteUserSuccess(users));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteContainer(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(getBackHost() + '/c/d', data);

      const { containers } = response.data;

      dispatch(slice.actions.deleteContainerSuccess(containers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setError() {
  return async (dispatch) => {
    dispatch(slice.actions.setErrorFalse());
  };
}
